import React from 'react';
import { graphql } from 'gatsby';
import { FieldValues } from 'react-hook-form';
import Container from '../../../primitives/grid/container';
import Typography from '../../../primitives/typography';
import Color, { colors } from '../../../primitives/colors';
import { borderRadius, breakpoints } from '../../../primitives/tokens';
import FormProvider from '../../../primitives/form-provider';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import Input from '../../../primitives/input';
import FormAlert from '../../../primitives/form-alert';
import useQueryParams from '../../../../hooks/use-query-params';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import Spinner from '../../../primitives/spinner';
import Button from '../../../primitives/button';

export type PoweredByUptickLoginFormProps = Queries.PoweredByUptickLoginFormPropsFragment;

interface UseWorkspaceUrlReturnable {
    isLoading: boolean
    errors: string[]
    workspaceData: {
        logoUrl: string,
        companyName: string,
        primaryColor: string,
        workspace: string,
    } | null
}
const useWorkspaceUrl = (): UseWorkspaceUrlReturnable => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [errors, setErrors] = React.useState<string[]>([]);
    const [workspaceData, setWorkspaceData] = React.useState<{
        logoUrl: string,
        companyName: string,
        primaryColor: string,
        workspace: string,
    } | null>(null);
    const query = useQueryParams();

    React.useEffect(() => {
        const workspace = query?.workspace;

        if (workspace) {
            fetch(`https://workspace-settings.onuptick.com/${workspace as string}`)
                .then((response) => {
                    if (!response.ok) {
                        return response.text().then((text) => { throw new Error(text); });
                    }
                    return response.json();
                })
                .then((data) => {
                    setWorkspaceData({
                        logoUrl: data.customer_details?.logo_url,
                        companyName: data.customer_details?.company_name,
                        primaryColor: data.customer_details?.primary_colour,
                        workspace,
                    });
                    setIsLoading(false);
                    return null;
                })
                .catch(({ message }) => {
                    setIsLoading(false);
                    setErrors([
                        message,
                    ]);
                });
        } else {
            setIsLoading(false);
        }
    }, [query]);

    return {
        isLoading,
        errors,
        workspaceData,
    };
};

const PoweredByUptickLoginForm = ({
    title,
    richText,
}: PoweredByUptickLoginFormProps): JSX.Element => {
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const [errors, setErrors] = React.useState<string[]>([]);

    const {
        isLoading,
        errors: workspaceErrors,
        workspaceData,
    } = useWorkspaceUrl();

    const onSubmit = React.useCallback((form: FieldValues) => {
        setErrors([]);
        setIsSubmitting(true);

        if (workspaceData?.workspace) {
            fetch(`https://workspace-settings.onuptick.com/${workspaceData.workspace}`)
                .then((response) => {
                    if (!response.ok) {
                        return response.text().then((text) => { throw new Error(text); });
                    }
                    return response;
                })
                .then(() => {
                    const customerServer = `https://${workspaceData.workspace}.onuptick.com/login/?email=${form.email || ''}`;
                    window.location.href = customerServer;
                    return null;
                })
                .catch(({ message }) => {
                    setIsSubmitting(false);
                    setErrors([
                        message,
                    ]);
                });
        }
    }, [workspaceData?.workspace]);

    if (isLoading) {
        return (
            <div css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                minHeight: '100vh',
            }}
            >
                <Spinner />
            </div>
        );
    }

    return (
        <div css={{
            position: 'relative',
            overflow: 'hidden',
        }}
        >
            <Container css={{
                marginTop: '10vh',
                marginBottom: '10vh',
                minHeight: '80vh',
                display: 'flex',
                alignItems: 'center',
                [breakpoints.mb]: {
                    minHeight: 'unset',
                    marginTop: '80px',
                    marginBottom: '80px',
                    display: 'unset',
                },
            }}
            >
                <Row>
                    <Col breakpoints={{ dt: { span: 5 } }}>
                        {workspaceData?.logoUrl && (
                            <img
                                src={workspaceData.logoUrl}
                                alt="Uptick Logo"
                                css={{
                                    height: '80px',
                                    marginBottom: '32px',
                                    objectFit: 'contain',
                                    objectPosition: 'left',
                                }}
                            />
                        )}
                        <div>
                            <Typography
                                fontSize={{
                                    dt: 3234,
                                }}
                                css={{
                                    [breakpoints.mb]: {
                                        textAlign: 'center',
                                    },
                                }}
                                fontWeight="light"
                            >
                                {title}
                            </Typography>
                        </div>
                        <FormProvider
                            onSubmit={onSubmit}
                            disabled={isSubmitting}
                        >
                            <Color
                                backgroundColor="grey50"
                                css={{
                                    borderRadius: borderRadius.medium,
                                    marginTop: '28px',
                                }}
                            >
                                <div css={{ padding: '10px' }}>
                                    <Input name="email" type="email" placeholder="Your email address" required />
                                </div>
                            </Color>
                            <div>
                                <Button
                                    css={{
                                        marginTop: '20px',
                                        backgroundColor: workspaceData?.primaryColor || colors.primaryOrange,
                                        color: colors.shadesWhite,
                                        padding: '0 40px',
                                        height: '48px',
                                        fontWeight: 500,
                                        borderRadius: borderRadius.small,
                                        fontSize: '13px',
                                    }}
                                    type="submit"
                                    loading={isSubmitting}
                                    className="mx-auto d-block"
                                >
                                    Sign In
                                </Button>
                            </div>
                        </FormProvider>
                        {[...errors, ...workspaceErrors].map(error => (
                            <div
                                css={{
                                    marginTop: '20px',
                                }}
                                key={error}
                            >
                                <FormAlert>{error}</FormAlert>
                            </div>
                        ))}
                    </Col>
                    <Col breakpoints={{ dt: { span: 6, start: 6 }, mb: { span: 4, hidden: true } }}>
                        <Typography
                            fontSize={{
                                dt: 1627,
                                mb: 1322,
                            }}
                            css={{
                                marginTop: '16px',
                                opacity: '0.7',
                                p: {
                                    marginBottom: '12px',
                                },
                                [breakpoints.mb]: {
                                    textAlign: 'center',
                                },
                            }}
                        >
                            <CustomStructuredTextRenderer data={richText} />
                        </Typography>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default PoweredByUptickLoginForm;

export const query = graphql`
    fragment PoweredByUptickLoginFormProps on DatoCmsPoweredByUptickLoginForm {
        title
        richText {
            blocks
            links
            value
        }
    }
`;
